import { IThunderboltEntry } from '@wix/editor-elements-types';

const entry: IThunderboltEntry = {
  loadComponent: hostAPI => {
    hostAPI.registerComponent(
      'PaginatedGridGallery',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/PaginatedGridDefaultSkin/PaginatedGridDefaultSkin.skin' /* webpackChunkName: "PaginatedGridGallery_PaginatedGridDefaultSkin" */
          ),
          import(
            './viewer/PaginatedGridGallery.controller' /* webpackChunkName: "PaginatedGridGallery_PaginatedGridDefaultSkin" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'PaginatedGridDefaultSkin',
    );

    hostAPI.registerComponent(
      'PaginatedGridGallery',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/PaginatedGridArrowsOutside/PaginatedGridArrowsOutside.skin' /* webpackChunkName: "PaginatedGridGallery_PaginatedGridArrowsOutside" */
          ),
          import(
            './viewer/PaginatedGridGallery.controller' /* webpackChunkName: "PaginatedGridGallery_PaginatedGridArrowsOutside" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'PaginatedGridArrowsOutside',
    );

    hostAPI.registerComponent(
      'PaginatedGridGallery',
      () => {
        return import(
          './viewer/skinComps/PaginatedGridRibbonArrows/PaginatedGridRibbonArrows.skin' /* webpackChunkName: "PaginatedGridGallery_PaginatedGridRibbonArrows" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'PaginatedGridRibbonArrows',
    );
  },
};

export default entry;
